import { icons } from 'config/constants/assets';

export interface RouteType {
  key: string;
  text: string;
  url: string;
}

export const routes: { [key: number]: RouteType[] } = {
  169: [
    { key: 'tokenomics', text: 'Tokenomics', url: 'tokenomics' },
    { key: 'goal', text: 'RoadMap', url: 'goal' },
    { key: 'howto', text: 'HowTo', url: 'howto' },
  ],
};

export const extraMenuItems: { [key: number]: any[] } = {
  169: [
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
      ],
    },
  ],
};
