import { FC } from 'react';
import { styled } from '@mui/material';
import { useWeb3React } from '@web3-react/core';

import { useAppDispatch } from 'state/hooks';
import { setIsWalletConnectModalOpen } from 'state/modal/modalSlice';
import { ContainedButton } from 'components/common/Button';

const ConnectWalletButton = styled(ContainedButton)(() => ({}));

interface Props {
  width?: number;
}

const ConnectWallet: FC<Props> = ({ width }) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();

  const onConnectWallet = () => {
    window.open("https://app.degenfund.tips", "_self")
    // if (!account) {
    //   dispatch(setIsWalletConnectModalOpen(true));
    // }
  };

  return (
    <ConnectWalletButton onClick={onConnectWallet} sx={{ height: '33px', width: width || 'auto' }} variant="outlined">
      Launch App
    </ConnectWalletButton>
  );
};

export { ConnectWallet };
