import { ChainInfo } from 'types/chain';
import { chains } from 'config/constants/assets';

export const DEFAULT_ACTIVE_CHAIN_ID: number = Number(process.env.REACT_APP_CHAIN_ID) || 666666666;

export const SUPPORTED_CHAINS: ChainInfo[] = [
  { name: 'Degen', chainId: 666666666, nativeCurrency: 'DEG', label: 'degen', isMainnet: true, logo: "" },
];

export const CHAIN_INFO: { [key: number]: any } = {
  666666666: {
    explorer: '',
    explorerName: '',
    label: 'Degen',
    logoUrl: chains.fantom,
    nativeCurrency: { name: 'Degen', symbol: 'DEG', decimals: 18 },
  },
};
