import React, { FC, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import {
  fetchPresaleInfoAsync,
  fetchPresaleUserInfoAsync,
} from 'state/actions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import MainLayout from 'layouts/MainLayout';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { setSelectedChainId } from 'state/chain/chainSlice';

const OverviewPage = React.lazy(() => import('./pages/Overview'));

const Router: FC = () => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);

  const [mainLoaded, setMainLoaded] = useState(false);

  const fetchData = async () => {
    try{
      if( isWalletChecked ){
        dispatch(fetchPresaleInfoAsync(selectedChainId));
        setMainLoaded(true);
      }
    }
    catch(err) {
      console.log(err)
    }
    if( Number(selectedChainId) === 137 ) {
      setMainLoaded(true);
    }
  };

  const fetchUserData = async () => {
    if (!account ) return;
    try{
      // fetch nft farm data
      // const accountTemp = '0x0A10daD90b9C6FB8B87BFf3857A4B012890C53A5';
      dispatch(fetchPresaleUserInfoAsync(selectedChainId, account));
      // featch user data theatre;
      // dispatch(fetchTheatreUserDataAsync(account, selectedChainId));
    }
    catch(err){
      console.log(err)
    }

    try{
// 
    }
    catch(err){
      // 
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, isWalletChecked]);

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, account]);

  useEffect(() => {
    // fetch farm data
    const interval = setInterval(() => {
      // if(  ){
        setCounter2(counter2 + 1);
      // }else {
      //     fetchUserData();
      //     clearInterval(interval);
      // }
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, selectedChainId, counter2, isWalletChecked]);

  useEffect(() => {
    if (!selectedChainId) {
          window.localStorage.setItem("selectedChainId", String(DEFAULT_ACTIVE_CHAIN_ID));
         dispatch(setSelectedChainId(String(DEFAULT_ACTIVE_CHAIN_ID)));
     }
    const interval = setInterval(() => {
      setCounter(counter + 1);
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedChainId, isWalletChecked])

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate replace to="/home" /> },
        { path: '/home', element: <OverviewPage /> },
      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;
